import React from "react"
import {
  Box,
  Heading,
  Text,
  Flex,
  Stack,
  Link as ChakraLink,
  List,
  ListItem,
  useDisclosure
} from "@chakra-ui/core"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import LatestNewsItem from "../../components/LatestNewsItem"
import NewsPreview from "../../components/NewsPreview"
import LightHoverButton from "../../components/chakraOverrides/LightHoverButton"
import makeBackgroundImageArray from "../../helpers/makeBackgroundImageArray"
import styled from "@emotion/styled"

const StyledInternalHTML = styled(Box)`
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }

  ul {
    margin-inline-start: 3ch;
  }
`

const NewsIndex = props => {
  const intl = useIntl()
  const { onOpen, isOpen, onClose } = useDisclosure()
  return (
    <Layout
      location={props.location}
      bg={makeBackgroundImageArray("news")}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box pt={12} maxW="800px" my={20}>
        <Heading fontSize="6xl" as="h1" fontWeight="bold">
          <FormattedMessage id="news.heading" />
        </Heading>
        <Text fontSize="lg" my={3} maxW="600px">
          <FormattedMessage id="news.subheading_1" />
        </Text>
      </Box>
      <Box my={8}>
        <Heading fontSize="4xl" fontWeight="bold" as="h2" my={5}>
          <FormattedMessage id="homepage.news" />
        </Heading>
        <Flex direction="row" wrap={["wrap", null, null, "nowrap"]}>
          <Stack mr={4} alignItems="flex-start">
            {props.data.fullPosts.edges
              .map(edge => edge.node)
              .map(node => (
                <NewsPreview
                  author={node.creator}
                  headline={node.title}
                  excerpt={node.content}
                  slug={node.link}
                />
              ))}
          </Stack>
          <Box
            minW={300}
            maxW={["100%", null, null, "25%"]}
            w="100%"
            my={[4, null, null, 0]}
          >
            <Heading fontSize="2xl" fontWeight="bold" mb={3}>
              <FormattedMessage id="homepage.latest_news" />
            </Heading>
            <Stack spacing={2} w="100%" alignItems="flex-start">
              {props.data.smallPosts.edges
                .map(edge => edge.node)
                .map(node => (
                  <LatestNewsItem
                    text={node.title}
                    slug={node.link}
                    date={new Date(node.pubDate).toLocaleDateString()}
                  />
                ))}
            </Stack>
          </Box>
        </Flex>
      </Box>
      <Box>
        <Heading as="h2" fontSize="4xl" my={4}>
          {intl.formatMessage({ id: "news.useful_information" })}
        </Heading>
        <Stack>
          <ChakraLink href="https://iccwbo.org/resources-for-business/incoterms-rules/incoterms-2020/">
            Incoterms 2020
          </ChakraLink>
          <ChakraLink href="https://www.uz.gov.ua/cargo_transportation/legal_documents/smgs/">
            СОГЛАШЕНИЕ О МЕЖДУНАРОДНОМ ЖЕЛЕЗНОДОРОЖНОМ ГРУЗОВОМ СООБЩЕНИИ
          </ChakraLink>
          <ChakraLink href="https://www.uz.gov.ua/cargo_transportation/tariff_conditions/transportation_in_ukraine/">
            Збірник тарифів на перевезення вантажів залізничним транспортом у
            межах України та пов'язані з ним послуги
          </ChakraLink>
          <ChakraLink href="https://www.uz.gov.ua/cargo_transportation/tariff_conditions/transit/">
            Тарифна політика
          </ChakraLink>
        </Stack>
        <Heading as="h3" fontSize="3xl" my={4}>
          {intl.formatMessage({ id: "news.abbreviations" })}
        </Heading>
        <StyledInternalHTML
          dangerouslySetInnerHTML={{
            __html: intl.formatHTMLMessage({ id: "news.content" }),
          }}
        />
        <Text my={4}>{intl.formatMessage({ id: "news.ctrl_enter" })}</Text>
      </Box>
    </Layout>
  )
}

export default NewsIndex

export const query = graphql`
  {
    fullPosts: allFeedRailInsider(
      limit: 4
      sort: { order: DESC, fields: pubDate }
    ) {
      edges {
        node {
          link
          content
          title
          creator
        }
      }
    }
    smallPosts: allFeedRailInsider(
      limit: 5
      sort: { order: DESC, fields: pubDate }
      skip: 4
    ) {
      edges {
        node {
          link
          title
          pubDate
        }
      }
    }
  }
`
