import React from "react"
import PropTypes from "prop-types"
import { Box, Link, Divider, Text } from "@chakra-ui/core"

const LatestNewsItem = props => (
  <>
    <Box p={2} w="100%">
      <Link fontSize="md" mb={3} href={props.slug}>
        {props.text}
      </Link>
      <Text fontSize="sm" color="gray.500">
        {props.date}
      </Text>
    </Box>
    <Divider />
  </>
)

LatestNewsItem.propTypes = {
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default LatestNewsItem
